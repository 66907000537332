<template>
  <UiTourDriver
    ref="driver"
    :tour="TOURS_IDS.ACTIVITY_MEETING_CREATION"
    :highlight
    :type="TOURS_TYPES.UPDATE"
    @destroyed="useMittEvent('tour:activityMeetingCreation')"
  />
</template>

<script setup lang="ts">
import type { DriveStep } from 'driver.js'
import type { UiTourDriver } from '#build/components'
import { TOURS_IDS, TOURS_TYPES } from '~/constants'

const driver = ref<InstanceType<typeof UiTourDriver> | null>(null)

onMounted(() => {
  useMittListen('tour:activityMeetingCreation', () => {
    if (driver.value?.driverObj) {
      driver.value.startTour()
    }
  })
})

const highlight = ref<DriveStep>({
  element: '#add-guest',
  popover: {
    title: 'Add a lead e-mail',
    description: `Easily incorporate lead emails with just one click or by typing directly into the guest field.`,
    nextBtnText: "Okay, I'll check!",
    showButtons: ['next'],
    onNextClick: () => {
      if (driver.value?.driverObj) {
        driver.value.driverObj.destroy()
      }
    },
  },
})

onUnmounted(() => {
  useMittRemove('tour:activityMeetingCreation')
})
</script>

<style scoped lang="scss"></style>
